import React from "react";
import { FaRocket, FaDollarSign, FaBullhorn } from "react-icons/fa"; // Import icons

const EarningOpportunityPage = () => {
  return (
    <>
      {/* Top Animation Section with Gradient and Icons */}
      <section className="mt-20 text-center text-pink-900 rounded-lg shadow-md">
        <div className="flex justify-center items-center text-2xl font-bold mb-4">
          <FaRocket className="mx-2 text-4xl" />
          <h1>
            💼✨ Unlock <span className="text-orange-500">New Earning Opportunities</span> 💸🚀
          </h1>
          <FaDollarSign className="mx-2 text-4xl" />
        </div>
        <div className="text-xl text-red-700 font-medium">
          <p className=" inline animate-fade-in-out">
            <FaBullhorn className="inline-block mr-2 text-2xl" />
            Start earning today and build your future!
          </p>
        </div>

        {/* Image Grid Section */}
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-8">
          {[
            {
              img: "https://wp-int-blog.s3.eu-central-1.amazonaws.com/wp-content/uploads/2023/10/12165354/Digital-Marketing-Specialist-freepik-1-scaled.jpg",
              title: "Explore New Opportunities",
              description: "Discover a world of earning potential!",
            },
            {
              img: "https://media.istockphoto.com/id/668087702/photo/professional-smart-young-woman-using-a-laptop-building-online-business.jpg?s=612x612&w=0&k=20&c=aZ4yDNsG5kPaWQAWVaMl6gyrUOPuU86-5x2P4x7H7MM=",
              title: "Level Up Your Skills",
              description: "Sharpen your skills and earn doing what you love!",
            },
            {
              img: "https://img.freepik.com/free-photo/elegant-smiling-woman-glasses-striped-shirt-using-laptop-computer-while-siting-table-kitchen_171337-13030.jpg",
              title: "Join Our Community",
              description: "Grow together with a supportive network!",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="cursor-pointer bg-gradient-to-r from-orange-100 to-orange-50 border border-gray-300 rounded-lg overflow-hidden shadow-lg hover:scale-105 transition-transform"
            >
              <img
                src={item.img}
                alt={item.title}
                className="w-full h-64 object-cover"
              />
              <div className="p-4 text-center">
                <h3 className="text-lg font-bold mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      <div className="">
        {/* Form Section */}
        <section className="mb-8 bg-white p-6 rounded-lg mt-6 text-center">
          <h3 className="text-2xl font-bold">Get Started Earning Today!</h3>
          <p className="my-4">Click the button below to fill out the form and join our community.</p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-6 mt-4">
            <a
              href="https://forms.gle/YTJNH9NVyxa5pL5i8"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-orange-500 animate-blink hover:bg-orange-600 px-6 py-3 rounded-full font-bold text-white shadow-lg transform hover:scale-105 transition-transform"
            >
              Join Now
            </a>
            <img
              // src="https://media.giphy.com/media/3o7abldj0b3rxrZUxW/giphy.gif"
              src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExaWxhdmZqZmU0NmJobHA3OG4zd3Q3MWczb3pzM2k4ZGNwaThoOXpwbiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/5fHTkXB7EOjTV7u8M6/giphy.webp"
              alt="Smiling HR Icon"
              className="w-40 h-32"
            />
          </div>
        </section>

        {/* Subscribe Section */}
        {/* <section className="bg-gradient-to-r from-purple-700 to-pink-400 text-white p-6 rounded-lg mt-6 text-center">
          <h3 className="text-2xl font-bold">Stay Updated! Subscribe With Us</h3>
          <p className="mt-2">Get the latest updates, opportunities, and more directly in your inbox!</p>
          <form
            action="#"
            className="mt-6 flex flex-col sm:flex-row items-center justify-center gap-4"
          >
            <input
              type="email"
              placeholder="Enter your email"
              className="px-4 py-2 rounded-lg border-none focus:outline-none text-gray-700 w-full sm:w-1/2"
              required
            />
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-full shadow-lg transform hover:scale-105 transition-transform font-bold"
            >
              Subscribe
            </button>
          </form>
        </section> */}
      </div>
      </section>

    </>
  );
};

export default EarningOpportunityPage;
