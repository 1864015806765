import React from "react";
import cake from '../../images/chocolate/cake.jpg'
import chocolate1 from '../../images/chocolate/chocolate1.png'
import chocolate2 from '../../images/chocolate/chocolate2.jpg'
import chocolate3 from '../../images/chocolate/chocolate3.jpg'
import chocolate4 from '../../images/chocolate/chocolate4.jpg'
import { Link } from "react-router-dom";

const Gallery = () => {
  const images = [
    {
      src: chocolate1,
      alt: "chocolate",
      name: "chocolate",
    },
    {
      src: chocolate2,
      alt: "chocolate",
      name: "chocolate",
    },
    {
      src: cake,
      alt: "cake",
      name: "cake",
    },
    {
      src: chocolate3,
      alt: "chocolate",
      name: "chocolate",
    },
    {
      src: chocolate4,
      alt: "chocolate",
      name: "chocolate",
    },
    // {
    //   src: "https://via.placeholder.com/300",
    //   alt: "Placeholder Image 6",
    //   name: "Image Six",
    // },
  ];

  return (
    <div className="max-w-6xl mx-auto my-10">
      <h2 className="text-2xl font-bold text-center mb-8 text-gray-800">Our Delicious Products</h2>
      <div className="flex flex-wrap gap-3 justify-center overflow-scroll scrollbar-hide h-96">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative shrink-0 w-[45%] group overflow-hidden rounded-lg shadow-lg cursor-pointer"
          >
            <Link to={'https://www.frugato.com/'}>
            {/* Image */}
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-40 object-cover transition-transform duration-300 group-hover:scale-110"
            />
            {/* Hover Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-300 flex items-center text-center justify-center">
              <p className="text-white font-medium text-lg opacity-0 group-hover:opacity-100">
                {image.alt}
              </p>
            </div>
            {/* Image Name */}
            <div className="bg-gray-100 p-2 text-center">
              <p className="text-sm text-gray-800 font-medium">{image.name}</p>
            </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
