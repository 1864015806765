import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleScrollToSection = (id) => {
    if (location.pathname !== "/") {
      // Navigate to the home page first
      navigate("/");
      setTimeout(() => {
        // Scroll to the target section after navigation
        scroller.scrollTo(id, {
          smooth: true,
          offset: -80,
          duration: 600,
        });
      }, 100);
    } else {
      // Directly scroll to the section if already on "/"
      scroller.scrollTo(id, {
        smooth: true,
        offset: -80,
        duration: 600,
      });
    }
  };

  return (
    <footer className="bg-orange-200 bg-opacity-70 md:py-10 py-2">
      <div className="container md:w-4/5 mx-auto md:px-4 px-8">
        <div className="flex flex-col md:flex-row justify-between gap-4">
          {/* Contact Section */}
          <div className="md:w-2/5">
            <h3 className="font-bold text-lg mb-4">Contact</h3>
            <p className="flex items-center hover:underline">
              <a
                href="mailto:foofin.innovation@gmail.com"
                className="flex items-center"
              >
                <i className="fas fa-envelope mr-2"></i>
                Email: foofin.innovation@gmail.com
              </a>
            </p>
            <p className="flex items-center mt-2 hover:underline">
              <a href="tel:7506639669" className="flex items-center">
                <i className="fas fa-phone-alt mr-2"></i>
                Contact: 7506639669
              </a>
            </p>
            <a
                href="#"
                className="flex items-start text-black no-underline hover:underline  mt-2"
              >
                <i className="fas fa-map-marker-alt text-black text-2xl mr-2"></i>
                <p>
                  Infront of Bassein Catholic Bank, Shop No 8, Parera Nagar, Rose
                  Nagar Housing Society, Naigaon Station Rd, Naigaon West,
                  Vasai-Virar, Maharashtra 401207
                </p>
              </a>
          </div>

          {/* Quick Links Section */}
          <div>
            <h3 className="font-bold text-lg mb-4">Quick Links</h3>
            <div className="flex gap-5">
              <ul className="space-y-2">
                <li>
                  <span
                    className="cursor-pointer font-medium underline"
                    onClick={() => handleScrollToSection("about")}
                  >
                    About Us
                  </span>
                </li>
                <li>
                  <span
                    className="cursor-pointer font-medium underline"
                    onClick={() => handleScrollToSection("services")}
                  >
                    Services
                  </span>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className="cursor-pointer font-medium underline"
                  >
                    Blog
                  </Link>
                </li>
              </ul>
              <ul className="space-y-2">
                <li>
                  <Link
                    to="/earning_opportunity"
                    className="cursor-pointer font-medium underline"
                  >
                    Earning Opportunity
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.frugato.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer font-medium underline"
                  >
                    Frugato
                  </a>
                </li>
                <li>
                  <span
                    className="cursor-pointer font-medium underline"
                    onClick={() => handleScrollToSection("contact")}
                  >
                    Contact
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Social Media Section */}
          <div>
            <h3 className="font-bold text-lg mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://www.instagram.com/frugatodotcom?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="text-xl">
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/foofin-innovation/"
                className="text-xl"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="mt-10 border-t border-gray-700 pt-4 text-center">
          <p className="text-sm">
            &copy; 2024 Foofin Innovation Pvt Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
