import React, { useState } from "react";
import vision from '../../images/about/vision.png'
import mission from '../../images/about/mission.png'

const About = () => {
  const [activeTab, setActiveTab] = useState("vision");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section id="about" className="py-12">
      <div className=" md:w-4/5 mx-auto px-6 md:space-y-16 space-y-10">
      <div className="space-y-10">
        <div className="flex justify-center">
        <h2 className="text-3xl font-bold uppercase  tracking-wide pb-2 border-b-2 inline border-b-orange-400">
          About Us
        </h2>
        </div>
        <p className="text-gray-700 text-lg leading-relaxed mb-4 mx-auto">
          <strong>Foofin Innovation Pvt Ltd</strong> is a leading name in the
          food manufacturing and processing industry, dedicated to delivering
          high-quality, nutritious, and innovative food products. Our core
          operations focus on the production and distribution of packaged food
          items, catering to both retail and wholesale markets.
        </p>
        <p className="text-gray-700 text-lg leading-relaxed mx-auto mb-12 ">
          At Foofin, we take pride in our diverse range of food solutions,
          featuring innovative flavors, superior taste, and a healthy bite in
          every product. Guided by a customer-centric approach, we aim to
          provide a seamless and satisfying experience from order placement to
          delivery.
        </p>
      </div>

       <div className="space-y-16">
        <div className="md:flex">
          <div className="md:w-2/5 flex justify-center">
          <img src={vision} className="w-1/2" alt="vision image" />
          </div>
          <div className="md:w-3/5 space-y-5">
          <h2 className="text-2xl font-semibold pb-2 border-b-2 border-b-orange-400 inline">vision</h2>
            <p className="text-justify">To be a globally recognized leader in the food industry, offering a wonderful customer experience through healthy and tasty bites. We aim to revolutionize food experiences by combining innovation, quality, and convenience with a focus on sustainability and well-being.</p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row">
          <div className="md:w-3/5 space-y-5">
          <h2 className="text-2xl font-semibold pb-2 border-b-2 border-b-orange-400 inline">mission</h2>
            <p className="text-justify">To deliver high-quality, ready-to-eat meals and packaged food products that combine exceptional taste with nutrition. By embracing innovation and sustainability, we strive to create a seamless customer experience while promoting health and ethical practices.</p>
          </div>
          <div className="md:w-2/5 flex justify-center">
          <img src={mission} className="w-1/2" alt="mission image" />
          </div>
        </div>
       </div>

      </div>
    </section>
  );
};

export default About;
