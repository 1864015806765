import React, { useState } from "react";
import feedback from "../../images/feedback/feedback.jpg";
import feedback2 from "../../images/feedback/feedback2.jpg";
import feedback3 from "../../images/feedback/feedback3.jpg";
import Modal from "../modal/Modal";

const reviews = [
  { image: feedback, id: Math.floor(Math.random()*1000) },
  { image: feedback3, id: Math.floor(Math.random()*1000) },
  { image: feedback2, id: Math.floor(Math.random()*1000) },
  { image: feedback, id: Math.floor(Math.random()*1000) },
  { image: feedback3, id: Math.floor(Math.random()*1000) },
  { image: feedback2, id: Math.floor(Math.random()*1000) },
];

const CustomReviewSlider = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-10 mb-10 pt-5">
      <h2 className="text-center text-2xl font-semibold mb-5">
        What Our Happy Customer Says
      </h2>
      <div className="relative overflow-hidden">
        <div className="slider-container flex animate-slide gap-2 py-5 hover:pause-animation">
          {reviews.concat(reviews).map((review, index) => (
            <div
              key={`${review.id}-${index}`} // Use the unique `id` from the review object
              onClick={() => {
                setImageIndex(index % reviews.length); // Ensure the index is within bounds
                setIsOpen(true);
              }}
              className="flex justify-center items-center shadow-lg rounded-lg md:w-60  bg-orange-50 transition-transform duration-300 ease-in-out transform hover:scale-[1.1] mx-2 shrink-0 cursor-pointer"
            >
              <img src={review.image} className="md:h-60 md:my-5 my-2 mx-3 md:mx-0 h-40 m-auto" alt="review image" />
            </div>
          ))}
        </div>
        {isOpen && (
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <button
              onClick={() => setIsOpen(false)}
              className="w-full text-end mb-4"
            >
              <i className="fa-solid fa-xmark text-xl"></i>
            </button>
            <img
              src={reviews[imageIndex].image}
              className="m-auto h-96"
              alt="review image"
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default CustomReviewSlider;
