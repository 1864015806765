import React from "react";
import { Link } from "react-scroll";
import welcomeVideo from '../../video/welcomeVideo.mp4'; // Import your video

const Hero = () => {
  return (
    <section className="relative w-full md:h-screen h-[70vh] pb-5" id='home'>
      {/* Video Background */}
      <video
        className="absolute inset-0 w-full h-full  object-cover z-0"
        src={welcomeVideo}
        autoPlay
        loop
        muted
      ></video>

      {/* Low Opacity Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center justify-center text-white text-center px-6 h-full">
        <h1 className="md:text-4xl text-2xl sm:text-5xl font-bold mb-6 leading-tight animate-fade-in">
          Welcome to <br />
          <span className="md:text-5xl text-2xl sm:text-6xl font-extrabold text-yellow-500 relative inline-block animate-reveal">
            Foofin Innovation Pvt Ltd
            <span className="absolute bottom-0 left-0 w-0 h-[4px] bg-orange-500 transition-all duration-700 hover:w-full"></span>
          </span>
        </h1>

        <p className="md:text-xl text-gray-200 leading-relaxed mb-8 max-w-4xl mx-auto animate-slide-up">
          Foofin Innovation Pvt Ltd is a leading player in the food manufacturing
          and processing industry, committed to delivering high-quality,
          nutritious, and innovative food products. Our core business
          encompasses the production and distribution of packaged food items,
          catering to both retail and wholesale markets. We specialize in
          ready-to-eat meals that provide convenience without compromising on
          taste or nutrition, designed for modern, fast-paced lifestyles.
        </p>

        <Link
          to="about"
          smooth={true}
          offset={-80}
          duration={600}
          className="px-6 py-3 cursor-pointer bg-orange-500 text-white font-semibold rounded-full shadow-lg hover:bg-orange-400 transform hover:scale-105 transition-all animate-slide-up"
        >
          Learn More
        </Link>
      </div>
    </section>
  );
};

export default Hero;
