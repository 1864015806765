import React from "react";
import { useParams } from "react-router-dom";
import {blogs} from '../../temp/temp'
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import SideBar from "../../components/sideBar/SideBar";
import FAQ from "../../components/FAQ/FAQ";
import adsBanner from '../../images/adsBanner.png'

function BlogDetail() {
  const { title } = useParams(); // Access the dynamic id from the route


  function replaceUnderscoresWithSpaces(input) {
    return input.replace(/_/g, " ");
  }
  // Find the blog by id
  const blog = blogs.find((blog) => blog.title === replaceUnderscoresWithSpaces(title));

  return (
    <div className="mt-16 md:p-4">

<Breadcrumb title={blog.title} />



<div className="md:flex mt-10">
<div className="md:w-4/5 md:px-10 px-7">
  <div>
    <h1 className="text-center text-2xl font-semibold">{blog.title}</h1>
    <p className="mt-5 text-justify">{blog.description}</p>
    <img
      src={blog.image}
      alt={blog.title}
      className="md:w-1/2 rounded-md h-60 object-cover m-auto mt-5"
    />
  </div>

  <div className="my-10">
    <h1 className="text-lg font-medium">What Makes Dark Chocolate a Healthy Choice?</h1>
    <p className="text-justify">
      Dark chocolate, especially varieties containing 70% or more cocoa, is a superfood in disguise. It’s rich in:
    </p>
    <ul className="list-disc pl-5 mt-3">
      <li><strong>Flavanols:</strong> Natural compounds that improve blood circulation and reduce inflammation.</li>
      <li><strong>Magnesium:</strong> Supports muscle function, bone health, and energy production.</li>
      <li><strong>Iron:</strong> Essential for transporting oxygen throughout the body.</li>
      <li><strong>Antioxidants:</strong> Combat oxidative stress, which can lead to chronic diseases.</li>
    </ul>
  </div>

  <div className="my-10">
    <h1 className="text-lg font-medium">Top Health Benefits of Dark Chocolate</h1>
    <ul className="list-disc pl-5 mt-3">
      <li><strong>Boosts Heart Health:</strong> Regular consumption of dark chocolate can lower blood pressure, improve cholesterol profiles, and enhance overall cardiovascular health.</li>
      <li><strong>Improves Brain Function:</strong> Flavanols in dark chocolate enhance memory, attention, and cognitive function by increasing blood flow to the brain.</li>
      <li><strong>Protects Skin:</strong> Antioxidants in dark chocolate protect your skin from UV damage, improve hydration, and increase elasticity.</li>
      <li><strong>Enhances Mood:</strong> Compounds like serotonin and phenylethylamine (PEA) in dark chocolate naturally boost mood and reduce stress.</li>
      <li><strong>Supports Weight Management:</strong> Dark chocolate can curb cravings and promote satiety when consumed in moderation.</li>
    </ul>
  </div>
  <div className="my-10">
    <h1 className="text-lg font-medium">How Much Dark Chocolate Should You Eat?</h1>
    <p className="text-justify">
      The recommended serving is around <strong>20-30 grams per day</strong> (approximately 2-3 squares). Too much can lead to excess calorie intake, so stick to high-quality chocolate with at least 70% cocoa content. At <strong>Frugato</strong>, we ensure our homemade dark chocolate provides a perfect balance of health and indulgence.
    </p>
  </div>
  <img src={adsBanner} className="md:w-[90%] m-auto" alt="" />

  <div className="my-10">
    <h1 className="text-lg font-medium">Why Choose Frugato’s Homemade Dark Chocolate?</h1>
    <p className="text-justify">
      When it comes to dark chocolate, quality matters. At <strong>Frugato by Fooofin Innovation Pvt. Ltd.</strong>, we offer:
    </p>
    <ul className="list-disc pl-5 mt-3">
      <li><strong>Authenticity:</strong> Made with premium, responsibly sourced cocoa.</li>
      <li><strong>Health Benefits:</strong> Free from artificial additives and packed with natural nutrients.</li>
      <li><strong>Rich Taste:</strong> Crafted for a smooth and indulgent flavor.</li>
    </ul>
    <p className="text-justify mt-3">
      Our mission is to offer a guilt-free indulgence that complements your healthy lifestyle. Discover the perfect snack or thoughtful gift with Frugato’s homemade dark chocolate.
    </p>
  </div>

  <div className="my-10">
    <h1 className="text-lg font-medium">Fun Facts About Dark Chocolate</h1>
    <ul className="list-disc pl-5 mt-3">
      <li><strong>Ancient Origins:</strong> Dark chocolate has been enjoyed since 1900 BC, with roots in Aztec and Mayan cultures.</li>
      <li><strong>Natural Mood Booster:</strong> Contains theobromine, which acts as a mild stimulant and enhances energy levels.</li>
      <li><strong>Brain Health:</strong> Studies suggest consuming dark chocolate before studying can improve focus and memory retention.</li>
    </ul>
  </div>
  <div className="my-10">
    <h1 className="text-lg font-medium">Tips for Incorporating Dark Chocolate into Your Diet</h1>
    <ul className="list-disc pl-5 mt-3">
      <li><strong>Snack Smart:</strong> Pair a square of dark chocolate with nuts or fruits for a balanced snack.</li>
      <li><strong>Add to Recipes:</strong> Use dark chocolate chips in oatmeal, smoothies, or baked goods.</li>
      <li><strong>Savor Slowly:</strong> Allow the chocolate to melt in your mouth to fully enjoy its flavor and richness.</li>
    </ul>
  </div>

  <footer className="my-10 text-center">
    <p>Looking to enjoy the health benefits of dark chocolate? Try <a href="https://www.frugato.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 font-semibold">Frugato by Fooofin Innovation Pvt. Ltd.</a> today. Indulge in authentic homemade dark chocolate crafted with love and care!</p>
  </footer>
  <div className="hidden md:block">
  <FAQ />
  </div>
</div>
<div className="hidden md:block w-2/5">
<SideBar />
</div>
</div>
<div className="md:hidden block">
<SideBar />
<FAQ />
</div>
    
    </div>
  );
}

export default BlogDetail;
