import React from "react";
import { Link } from "react-router-dom";
import { blogs } from "../../temp/temp";

function Blog() {

  function replaceSpacesWithUnderscores(input) {
    return input.replace(/\s+/g, "_");
  }
 
  return (
    <div className="py-5">
      <div className="container mx-auto p-8 mt-12">
        <h1 className="text-3xl font-bold uppercase tracking-wide pb-2 border-b-2 inline border-b-orange-400">Blogs</h1>
        <p className="text-gray-600 my-6">Welcome to the Blog!</p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {blogs.map((blog, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-orange-100 to-orange-50 border cursor-pointer border-gray-200 rounded-lg shadow-lg hover:scale-105 hover:shadow-xl transition-transform duration-300 p-6"
            >
              <Link  to={replaceSpacesWithUnderscores(blog.title)}>
              <img
                src={blog.image}
                alt={`Blog ${index + 1}`}
                className="w-full h-40 object-cover rounded-lg mb-4"
              />
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                {blog.title}
              </h2>
              <p className="text-gray-600 mb-4 h-[70px] text-justify overflow-hidden line-clamp-3">
  {blog.description}
</p>
              <button
                href={blog.link}
                className="border-2 float-right border-orange-400 px-4 py-2 rounded hover:bg-orange-400 hover:text-white transition duration-300"
              >
                Read More
              </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
