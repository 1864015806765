import React, { useState,useRef } from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    form_name: "",
    form_email: "",
    phone:"",
    message: ""
  });
  const [sending, setSending] = useState(false);

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Starts with 6-9, followed by 9 digits
    return phoneRegex.test(phone); // Returns true if valid, false otherwise
  };


  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

 if(!validatePhoneNumber(formData.phone)){
  toast.error('Please Enter Valid Number')
  return
 }

    setSending(true);

    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY_EMAILJS,
      })
      .then(
        () => {
          setSending(false);
          console.log('SUCCESS!');
          toast.success("Message sent successfully!");
          setFormData({
            name: "",
            email: "",
            phone:"",
            message: ""
          })
        },
        (error) => {
          setSending(false);
          console.log('FAILED...', error.text);
          toast.error("There was an error sending your message.");
        },
      );
  };

  return (
    <>
      <section id="contact" className="md:w-4/5 m-auto md:space-y-10 space-y-5">
        <div className="flex justify-center">
          <h2 className="text-3xl font-bold uppercase tracking-wide pb-2 border-b-2 inline border-b-orange-400">
            Contact Us
          </h2>
        </div>
        <div className="flex flex-wrap justify-center items-start md:p-6 p-2 gap-6">
          {/* Contact Form */}
          <div className="flex-1 min-w-[300px] max-w-[500px] p-6">
            <h2 className="text-xl font-semibold mb-4">Send your queries?</h2>
            <form ref={form} onSubmit={sendEmail} className="space-y-4">
              <input
                type="text"
                name="from_name"
                value={formData.name}
                placeholder="Please Enter Your Name"
                required
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400"
              />
              {/* <input
                type="email"
                name="from_email"
                value={formData.email}
                placeholder="Please Enter Your Gmail Id"
                required
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400"
              /> */}
              <div className="flex gap-3">
               <p className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400">+91</p>
              <input
                type="phone"
                name="phone"
                value={formData.phone}
                placeholder="Please Enter Your Phone Number"
                required
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400"
                />
                </div>
              <textarea
                name="message"
                value={formData.message}
                placeholder="Your Message"
                rows="5"
                required
                onChange={handleChange}
                className="w-full md:h-36 h-28 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400 resize-none"
              ></textarea>
              <button
                type="submit"
                className="w-full px-4 py-2 hover:text-white bg-orange-400 font-semibold rounded-md hover:bg-orange-600 transition"
                disabled={sending}
              >
                {sending ? "Sending..." : "Send Message"}
              </button>
            </form>
          </div>

          {/* Contact Details */}
          <div className="flex-1 min-w-[300px] max-w-[500px] p-6">
            <h2 className="text-xl font-semibold mb-4">Get in Touch</h2>
            <div className="flex items-center mb-4">
              <a
                href="mailto:foofin.innovation@gmail.com"
                className="flex items-center text-black no-underline hover:underline"
              >
                <i className="fas fa-envelope text-black text-2xl mr-2"></i>
                <p>Email: foofin.innovation@gmail.com</p>
              </a>
            </div>
            <div className="flex items-center mb-4">
              <a
                href="tel:7506639669"
                className="flex items-center text-black no-underline hover:underline"
              >
                <i className="fas fa-phone-alt text-black text-2xl mr-2"></i>
                <p>Contact: 7506639669</p>
              </a>
            </div>
            <div className="flex items-start mb-4">
              <a
                href="#"
                className="flex items-start text-black no-underline hover:underline"
              >
                <i className="fas fa-map-marker-alt text-black text-2xl mr-2"></i>
                <p>
                  Infront of Bassein Catholic Bank, Shop No 8, Parera Nagar, Rose
                  Nagar Housing Society, Naigaon Station Rd, Naigaon West,
                  Vasai-Virar, Maharashtra 401207
                </p>
              </a>
            </div>
            {/* Social Media Links */}
            <div className="flex gap-4 mt-6">
              <a
                href="https://www.linkedin.com/company/foofin-innovation/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                className="text-black text-2xl hover:text-orange-400"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://www.instagram.com/frugatodotcom?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                className="text-black text-2xl hover:text-orange-400"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Toast Container */}
      <ToastContainer />
    </>
  );
};

export default Contact;
