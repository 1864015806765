import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { useLocation } from "react-router";
import logo from "../../images/logo.png";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleScrollToSection = (id) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(id, {
          smooth: true,
          offset: -80,
          duration: 600,
        });
      }, 100);
    } else {
      scroller.scrollTo(id, {
        smooth: true,
        offset: -80,
        duration: 600,
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsNavbarVisible(true);
        setIsMenuOpen(false);
      } else {
        setIsNavbarVisible(false);
      }
    };

    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll);
    } else {
      setIsNavbarVisible(true);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  // Prevent scrolling when the menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <nav
      className={`fixed top-0 w-full z-20 bg-white  shadow-lg h-16 transition-transform duration-300 ${
        isNavbarVisible ? "translate-y-0" : "-translate-y-full"
      } ${isMenuOpen ? "bg-opacity-100" : ""}`}
    >
      <div className="container md:w-4/5 mx-auto flex justify-between items-center px-4 h-full">
        {/* Logo Section */}
        <Link
          to="/"
          onClick={() => {isDropdownOpen(false); handleScrollToSection("home");}}
          className="flex items-center text-xl font-bold"
        >
          <img src={logo} className="w-16" alt="Logo" />
        </Link>

        {/* Mobile Menu Icon */}
        <button className="text-2xl md:hidden" onClick={()=>{toggleMenu();setIsDropdownOpen(false)}}>
          <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </button>

        {/* Navigation Links */}
        <div
          className={`md:flex md:items-center h-screen md:static absolute bg-black items-end bg-opacity-50 text-black md:flex-row flex-col flex md:bg-transparent top-16 left-0 w-full md:w-auto md:h-auto transition-all ease-linear duration-300 ${
            isMenuOpen && isNavbarVisible ? "translate-x-0" : "translate-x-full"
          } md:translate-x-0`}
        >
          <ul className="bg-white md:bg-transparent h-full w-4/5 flex flex-col md:flex-row items-center ">
            <li className="py-2 md:py-0 md:mx-4">
              <Link
                to="/"
                className="text-xl hover:text-orange-400 transition-all ease-linear duration-300"
                onClick={() => {
                  setIsMenuOpen(false);
                  handleScrollToSection("home");
                  setIsDropdownOpen(false);
                }}
              >
                Home
              </Link>
            </li>
            <li className="py-2 md:py-0 md:mx-4">
              <span
                className="text-xl hover:text-orange-400 transition-all ease-linear duration-300 cursor-pointer"
                onClick={() => {
                  setIsMenuOpen(false);
                  handleScrollToSection("about");
                  setIsDropdownOpen(false);
                }}
              >
                About
              </span>
            </li>
            <li className="relative py-2 md:py-0 md:mx-4">
              <button
                className="text-xl hover:text-orange-400 transition-all ease-linear duration-300 flex items-center"
                onClick={toggleDropdown}
              >
                Services <i className="ml-2 fas fa-caret-down"></i>
              </button>
              {isDropdownOpen && (
                <ul className="absolute bg-white  rounded mt-2 shadow-lg">
                  <li className="px-4 py-2">
                    <a
                    onClick={()=>{toggleDropdown();toggleMenu()}}
                      href="https://www.frugato.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-xl hover:text-orange-400 transition-all ease-linear duration-300"
                    >
                      Our Products/Frugato
                    </a>
                  </li>
                  <li className="px-4 py-2">
                    <Link
                      to="/earning_opportunity"
                      className="text-xl hover:text-orange-400 transition-all ease-linear duration-300"
                      onClick={()=>{toggleDropdown();toggleMenu()}}
                    >
                      Earning Opportunity
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="py-2 md:py-0 md:mx-4">
              <Link
                to="/blog"
                className="text-xl hover:text-orange-400 transition-all ease-linear duration-300"
                onClick={() => {
                  setIsMenuOpen(false);
                  setIsDropdownOpen(false);
                }}
              >
                Blog
              </Link>
            </li>
            <li className="py-2 md:py-0 md:mx-4">
              <span
                className="text-xl hover:text-orange-400 transition-all ease-linear duration-300 cursor-pointer"
                onClick={() => {
                  setIsMenuOpen(false);
                  handleScrollToSection("contact");
                  setIsDropdownOpen(false);
                }}
              >
                Contact
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
