import React, { useEffect } from "react";

function Modal({ children, isOpen, onClose }) {
  // Disable scrolling when the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Reset on unmount
    };
  }, [isOpen]);

  if (!isOpen) return null; // Do not render the modal if it's not open

  return (
    <div
      className="fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose} // Close the modal when clicking on the overlay
    >
      <div
        className="bg-white p-6 rounded shadow-lg max-w-lg w-full mx-2"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        {children}
      </div>
    </div>
  );
}

export default Modal;
