import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrumb({title}) {
  return (
    <div className='flex gap-2 items-center font-semibold text-lg md:pl-10 px-5' >
        <Link to={'/'} className='pb-1 hover:text-orange-400 border-b-2 border-b-transparent transition-all ease-linear duration-300  hover:border-b-orange-400' >Home</Link>  
         <i className="fa-solid fa-chevron-right"></i> 
         <Link to={'/blog'} className='pb-1 hover:text-orange-400 border-b-2 border-b-transparent transition-all ease-linear duration-300  hover:border-b-orange-400' >Blog</Link> 
          <i class="fa-solid fa-chevron-right"></i> 
          <Link className='text-nowrap overflow-hidden text-ellipsis pb-1 text-orange-400 border-b-2 transition-all ease-linear duration-300 border-b-orange-400' >{title}</Link>
           </div>
  )
}

export default Breadcrumb