import React from "react";
import { Link } from "react-router-dom";
import foofinImg from '../../images/services/foofin.png'
import earning from '../../images/services/earning.png'

function OurServices() {
  const services = [
    {
      image: foofinImg,
      title: "Frugato",
      description:
        "Frugato was created with a passion for crafting extraordinary chocolate that delights and cares for both people and the planet.",
      link: 'https://www.frugato.com/'
    },
    {
      image: earning,
      title: "Earning Opportunity",
      description:
        "Frugato offers a luxurious earning opportunity with chocolates crafted to delight your taste buds.",
      link: '/earning_opportunity'
    },
  ];

  return (
    <div className="py-10 md:w-4/5 m-auto md:space-y-10 px-5" id="services">
      <div className="flex justify-center">
        <h2 className="text-3xl font-bold uppercase tracking-wide pb-2 border-b-2 inline border-b-orange-400">
          Our Services
        </h2>
      </div>
      <div className="flex flex-wrap justify-center gap-6 px-5" style={{ perspective: '1000px' }}>
        {services.map((service, index) => (
          <Link
            to={service.link}
            target={service.title === 'Frugato' ? "_block" : ""}
            key={index}
            className="relative group w-full sm:w-1/2 md:w-2/5 p-5 mt-10 md:mt-0 h-80 bg-gradient-to-r from-orange-100 to-orange-50 shadow-lg rounded-lg overflow-hidden transition-all ease-linear duration-300 cursor-pointer hover:scale-105"
          >
            {/* Front Side */}
            <div className="absolute inset-0 backface-hidden flex flex-col">
              <img
                src={service.image}
                alt={service.title}
                className="w-full h-60 object-cover rounded-md md:mt-0 p-5"
              />
              <h3 className="text-xl md:pb-0 px-5 font-semibold text-gray-800 mt-3">{service.title}</h3>
            </div>
            {/* Hover Effect and Back Side */}
            <div className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center text-center opacity-0 group-hover:opacity-100 transition-all duration-300 backface-hidden">
              <div className="text-white text-xl font-semibold">
                <span className="block mb-3">{service.description}</span>
                <span className="text-lg font-medium border-2 border-orange-400 px-4 py-2 bg-orange-400 rounded-md text-black">
                  Check Service
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default OurServices;
