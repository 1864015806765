import React from "react";
import './index.css';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/navbar";
import Blog from './pages/Blog/blog';
import EarningOpportunity from "./pages/earning_opportunity/EarningOpportunity";
import Footer from './components/Footer/footer';
import Home from "./pages/home/Home";
import BlogDetail from './pages/blogDetail/BlogDetail';

function App() {
 

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/earning_opportunity" element={<EarningOpportunity />} />
          <Route path="/blog/:title" element={<BlogDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
