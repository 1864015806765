 import blog1 from '../images/blog/blog1.png'
 import blog2 from '../images/blog/blog2.jpg'
 import blog3 from '../images/blog/blog3.jpg'
 
 const blogs = [
    {
      image: blog1,
      title: "Health Benefits of Dark Chocolate and How Much You Should Eat",
      description:
        "Dark chocolate isn’t just a delightful treat; it’s a treasure trove of health benefits, backed by science. Loved for its rich flavor and versatility, this indulgence also comes packed with powerful antioxidants and nutrients that support overall wellness. At Frugato by Fooofin Innovation Pvt. Ltd., we craft premium, authentic homemade dark chocolate, ensuring that every bite is as wholesome as it is delicious",
      id: 1,
    },
    {
      image: blog2,
      title: "The Incredible Health Benefits of Dark Chocolate: Boost Your Wellness",
      description: "Dark chocolate isn’t just a delightful treat; it’s a powerhouse of antioxidants and nutrients. With numerous health benefits, including heart health, brain function, and mood enhancement, dark chocolate is a delicious way to support your overall wellness. At Frugato by Fooofin Innovation Pvt. Ltd., we craft premium, authentic homemade dark chocolate, ensuring every bite is packed with both taste and health benefits.",
      id: 2,
    },
    {
      image: blog3,
      title: "Why Dark Chocolate is the Perfect Snack for Your Health",
      description: "Dark chocolate is more than just a treat — it’s a natural source of powerful antioxidants, magnesium, and iron. Regular consumption can help improve heart health, enhance cognitive function, and elevate your mood. Discover the benefits of indulging in our premium homemade dark chocolate from Frugato by Fooofin Innovation Pvt. Ltd., where quality meets wellness in every bite.",
      id: 3,
    },
    {
      image: blog2,
      title: "Unlock the Secrets of Dark Chocolate: Delicious & Healthy",
      description: "From improving heart health to boosting brain function, dark chocolate is packed with benefits that go beyond its rich flavor. At Frugato by Fooofin Innovation Pvt. Ltd., our dark chocolate is crafted with care, providing a wholesome, indulgent experience that supports your well-being. Learn more about how dark chocolate can be part of a balanced, healthy lifestyle.",
      id: 4,
    },
  ];

  export {blogs}