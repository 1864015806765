import React from 'react';
import Hero from '../../components/Hero/hero';
import About from '../../components/About/about';
import OurServices from '../../components/Services/services';
import Contact from '../../components/Contact/contact';
import ReviewSlider from '../../components/slider/Slider';
// import welcome from '../../audio/welcome.mp3';

function Home() {
  // useEffect(() => {
  //   let audioPlayed = false;

  //   const playSound = async () => {
  //     try {
  //       const audio = new Audio(welcome);
  //       await audio.play();
  //       console.log("Audio played successfully!");
  //       audioPlayed = true; 
  //     } catch (error) {
  //       console.error("Failed to play audio. User interaction may be required.", error);
  //     }
  //   };

  //   const handleScroll = () => {
  //     if (!audioPlayed) {
  //       playSound();
  //     }
  //   };

   
  //   playSound();

   
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
   
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div className="">
      <Hero />
      <About />
      <OurServices />
      <Contact />
      <ReviewSlider />
    </div>
  );
}

export default Home;
