import React, { useState } from "react";

const faqData = [
   {
    question: "What are the health benefits of dark chocolate?",
    answer: `
      Dark chocolate, especially varieties containing 70% or more cocoa, offers a wide range of health benefits. 
      It’s rich in flavanols, magnesium, iron, and antioxidants that help with heart health, brain function, skin protection, and mood enhancement.
      Regular consumption of dark chocolate (20-30 grams per day) can lower blood pressure, improve cholesterol, 
      boost cognitive function, protect the skin from UV damage, and curb cravings. For a healthy option, 
      we recommend enjoying Frugato’s premium homemade dark chocolate, which provides all these benefits in every bite.
    `,
  },
  {
    question: "How much dark chocolate should I eat per day?",
    answer: "The recommended serving is around 20-30 grams per day (approximately 2-3 squares). Moderation is key, as consuming too much can lead to excess calorie intake. Choose high-quality chocolate with at least 70% cocoa content for maximum health benefits.",
  },
  {
    question: "What makes Frugato's dark chocolate different?",
    answer: "Frugato’s dark chocolate stands out because it’s made with premium, responsibly sourced cocoa. Our homemade dark chocolate is free from artificial additives and packed with natural nutrients, offering a smooth, indulgent flavor while providing numerous health benefits.",
  },
  {
    question: "Can dark chocolate help with weight management?",
    answer: "Yes, dark chocolate can help curb cravings and promote satiety when consumed in moderation. The natural compounds in dark chocolate can help regulate appetite and contribute to a balanced diet, making it a satisfying and guilt-free snack.",
  },
  {
    question: "What is the recommended cocoa content for health benefits?",
    answer: "For the best health benefits, opt for dark chocolate that contains at least 70% cocoa. This high cocoa content ensures a rich concentration of flavanols, antioxidants, and other nutrients that contribute to heart health, brain function, and overall wellness.",
  },
  {
    question: "What are some fun facts about dark chocolate?",
    answer: `
      - Dark chocolate has been enjoyed since 1900 BC, with its roots in Aztec and Mayan cultures.
      - It contains theobromine, which acts as a mild stimulant and enhances energy levels.
      - Consuming dark chocolate before studying may improve focus and memory retention, thanks to its flavanol content.
    `,
  },
  {
    question: "How can I incorporate dark chocolate into my diet?",
    answer: `
      - Snack Smart: Pair a square of dark chocolate with nuts or fruits for a balanced snack.
      - Add to Recipes: Use dark chocolate chips in oatmeal, smoothies, or baked goods.
      - Savor Slowly: Allow the chocolate to melt in your mouth to fully enjoy its flavor and richness.
    `,
  },
  {
    question: "How is Frugato’s dark chocolate made?",
    answer: "At Frugato, we craft our dark chocolate with care, using premium, responsibly sourced cocoa and traditional techniques to preserve flavor and nutrition. Each batch is made in small quantities to ensure the highest quality, offering both an indulgent and health-conscious treat.",
  },
  {
    question: "Is dark chocolate good for brain health?",
    answer: "Yes, the flavanols in dark chocolate improve blood flow to the brain, which enhances memory, attention, and cognitive function. Studies suggest that consuming dark chocolate can help improve focus, making it a great brain-boosting snack.",
  },
  {
    question: "Can dark chocolate improve my mood?",
    answer: "Yes, dark chocolate contains compounds like serotonin and phenylethylamine (PEA), which naturally boost mood and reduce stress. It’s a delicious way to lift your spirits while enjoying a nutritious treat.",
  },
  {
    question: "What makes dark chocolate a heart-healthy choice?",
    answer: "Dark chocolate, especially with high cocoa content, has been shown to lower blood pressure, improve cholesterol profiles, and enhance cardiovascular health. Its antioxidant properties also help combat oxidative stress, which is beneficial for heart health.",
  },
  {
    question: "What are the origins of dark chocolate?",
    answer: "Dark chocolate dates back to the Aztec and Mayan civilizations, where it was consumed as a beverage during rituals and celebrations. Over time, it evolved into the delicious treat we enjoy today, with a rich history that spans over 3,000 years.",
  },
  {
    question: "Can dark chocolate protect my skin?",
    answer: "Yes, the antioxidants in dark chocolate can protect your skin from UV damage, improve hydration, and increase elasticity, making it a great addition to your skincare routine when consumed regularly in moderation.",
  },
];



const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="md:max-w-2xl my-10 md:p-6 p-6">
      <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Frequently Asked Questions</h2>
      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="border border-gray-300 rounded-lg">
            <button
              className="w-full text-left p-4 bg-white hover:bg-gray-50 focus:outline-none flex justify-between items-center"
              onClick={() => toggleFAQ(index)}
            >
              <span className="font-medium text-gray-800">{faq.question}</span>
              <span className="text-gray-500">
                {activeIndex === index ? <i className="fa-sharp fa-solid fa-chevron-up"></i> : <i className="fa-sharp fa-solid fa-chevron-down"></i>}
              </span>
            </button>
            {activeIndex === index && (
              <div className="p-4 text-gray-700 bg-gray-50">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
