import React from 'react'
import foofin from '../../images/services/foofin.png'
import { Link } from 'react-router-dom'
import Gallery from '../Gallery/gallery'

function SideBar() {
  return (
    <div className='md:w-full p-5 md:-mt-20'>
        <div>
        <img className='rounded-full h-36 w-76 m-auto shadow-xl' src={foofin} alt="" />
        </div>
        <div className='bg-orange-200 bg-opacity-50 -mt-20 pt-24 p-5'>
           <div className='flex flex-col justify-center items-center'>
            <h1 className='text-2xl font-semibold text-center'>Welcome!</h1>
            <p className='text-justify mt-2' >Frugato was born out of a passion for creating extraordinary chocolate that tells a story with every bite. Our journey began with a simple belief: chocolate should not only taste incredible but also be made with care for the planet and its people.</p>
         <Link target='_block' to='https://www.frugato.com/'>
          <button className='bg-orange-400 mt-5 py-2 px-4 rounded-3xl font-medium hover:bg-orange-500'>Check It Here</button>
         </Link>
         <Gallery />
           </div>
        </div>
    </div>
  )
}

export default SideBar